<template>
  <div class="meeting">
    <!-- <icy-header :title="title"></icy-header> -->
    <van-tabs
      sticky
      color="#F2C16F"
      v-model="active"
      swipeable
      lazy-render
      @change="tabChanges"
    >
      <!-- <van-badge :content="num" v-if="num > 0 ? true : false"> -->
      <van-tab
        :title="item.title"
        :badge="item.badge"
        v-for="(item, index) in meetingList"
        :key="index"
      >
        <meeting-list
          class="tab-list"
          :status="item.status"
          :type="type"
        ></meeting-list>
      </van-tab>
      <!-- </van-badge> -->
    </van-tabs>
  </div>
</template>

<script>
export default {
  data() {
    return {
      active: 0,

      // 0=>'我创建的' 1=>'我主持的' 2=>'我参与的'  默认0 type

      // status 0=> '待召开' 1 => '进行中', 2 => '已完成'  默认全部  传递后台的！！！
      meetingList: [
        {
          title: "全部",
          badge: "",
          status: ""
        },
        {
          title: "待召开",
          badge: "",
          status: "0"
        },
        {
          title: "进行中",
          badge: "",
          status: "1"
        },
        {
          title: "已完成",
          badge: "",
          status: "2"
        }
      ],
      type: "",
      title: "",
      titleMap: {
        0: "我发起的会议",
        2: "我参与的会议",
        1: "我主持的会议"
      }
    };
  },
  created() {
    this.$title("会议列表");
  },
  activated() {
    this.$title("会议列表");
    this.initPage();
    let scrollTop = this.$localStore.get("meetingScrollTop");
    window.scrollTo(0, scrollTop);
  },
  beforeRouteLeave(to, from, next) {
    const getScrollTop = () => {
      var scrollTop =
        document.documentElement.scrollTop ||
        window.pageYOffset ||
        document.body.scrollTop;
      return scrollTop;
    };
    let scrollTop = getScrollTop();
    this.$localStore.set("meetingScrollTop", scrollTop);
    next();
  },
  mounted() {},
  methods: {
    tabChanges(e) {
      this.$router.replace({ query: { ...this.$route.query, active: e } });
    },
    initPage() {
      this.$title(this.title);
      this.active = parseInt(this.$route.query.active) || 0;
      this.type = parseInt(this.$route.query.type) || "0" + "";
      this.title = this.titleMap[this.type];
      this.getUserInfo();
    },
    async getUserInfo() {
      const resp = await this.$API.get("userInfo", false).then(resp => resp);
      if (resp.code == 1000) {
        let sendNum = "";
        let joinNum = "";
        let hostNum = "";
        let sendWaitNum = "";
        let joinWaitNum = "";
        let hostWaitNum = "";
        if (resp.data.info.meeting_count) {
          sendNum = resp.data.info.meeting_count + "";
        }
        if (resp.data.info.meeting_user_count) {
          joinNum = resp.data.info.meeting_user_count + "";
        }
        if (resp.data.info.host_meeting_count) {
          hostNum = resp.data.info.host_meeting_count + "";
        }
        if (resp.data.info.wait_meeting_count) {
          sendWaitNum = resp.data.info.wait_meeting_count + "";
        }
        if (resp.data.info.wait_meeting_user_count) {
          joinWaitNum = resp.data.info.wait_meeting_user_count + "";
        }
        if (resp.data.info.wait_host_meeting_count) {
          hostWaitNum = resp.data.info.wait_host_meeting_count + "";
        }

        // meet 发起的 host 主持的 meeting_user 参加的
        // 0=>'我创建的' 1=>'我主持的' 2=>'我参与的'  默认0 type
        if (this.type == 0) {
          // 1待召开 2进行中
          this.meetingList[1].badge = sendWaitNum;
          this.meetingList[2].badge = sendNum;
        } else if (this.type == 1) {
          this.meetingList[1].badge = hostWaitNum;
          this.meetingList[2].badge = hostNum;
        } else if (this.type == 2) {
          this.meetingList[1].badge = joinWaitNum;
          this.meetingList[2].badge = joinNum;
        }
      }
    }
  },
  destroyed() {}
};
</script>
<style lang="less" scoped>
.meeting {
  height: 100%;
}
.tab-list {
  background: #f7f7f7;
}
</style>
